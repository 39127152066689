<template>
  <div>
    <el-dialog title="更换负责人" :visible.sync="peopleShow" :show-close="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="负责人">
          <el-select style="width: 100%;" filterable v-model="ruleForm.userCode" placeholder="请选择">
            <el-option :label="item.name" :value="item.code" v-for="(item, i) in userList" :key="i"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="onSubmit('ruleForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';

import { userList } from '../../api';
export const projectChangeSubject = api()('project.changeSubject.json');
export default {
  props: {
    code: String,
    peopleShow: Boolean
  },
  data () {
    return {
      userList: [],
      ruleForm: {
        userCode: '',
      },
      rules: {
        userCode: [{ required: true, message: '请输入负责人姓名', trigger: 'blur' }],


      },
    }
  },
  mounted () {

    this.userLogoData()

  },
  methods: {
    async userLogoData () {
      const params = {
        keywords: '',
        pageNumber: 1,
        pageSize: 10000
      };
      const res = await userList(params);
      this.userList = res.list.filter(it => it.isAuthenticated)
    },
    closeDialog () {
      this.$emit('update:peopleShow', false);
      this.$emit('close', this.peopleShow);
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          projectChangeSubject({
            ...this.ruleForm,
            code: this.code
          }
          ).then(() => {

            this.closeDialog()
          });
        } else {
          return false;
        }
      });
    }
  },
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>